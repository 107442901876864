import React from "react";
import { Spin, Modal } from "antd";

const index = ({ loading }) => {
  return (
    <Modal
      visible={loading}
      centered
      closable={false}
      footer={false}
      className="loading"
    >
      <div style={{ textAlign: "Center" }}>
        <Spin />
      </div>
    </Modal>
  );
};

export default index;
