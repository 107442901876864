import React, { lazy, Suspense } from "react";

import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Loading from "./components/Loading";

import "./scss/styles.css";
import "antd/dist/antd.css";
import "antd/dist/antd.less";

const Home = lazy(() => import("./views/Home"));
const Search = lazy(() => import("./views/Search"));
const Guide = lazy(() => import("./views/Guide"));
const Photo = lazy(() => import("./views/Photo"));
const Category = lazy(() => import("./views/Category"));
const Location = lazy(() => import("./views/Location"));
const Call = lazy(() => import("./views/Call"));
const Document = lazy(() => import("./views/Document"));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" render={(props) => <Home {...props} />} />
          <Route path="/search" render={(props) => <Search {...props} />} />
          <Route
            path="/guide/:from/:id"
            render={(props) => <Guide {...props} />}
          />
          <Route path="/photo" render={(props) => <Photo {...props} />} />
          <Route path="/category" render={(props) => <Category {...props} />} />
          <Route path="/location" render={(props) => <Location {...props} />} />
          <Route path="/call" render={(props) => <Call {...props} />} />
          <Route path="/document" render={(props) => <Document {...props} />} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
